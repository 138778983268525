.disabled-button {
    background-color: #e9f0ff;
    color: black;
    border-radius: 4px;
    border-color: #6495ED;
    border-width: 2px;
    padding: 8px 16px;
    margin-right: 10px;
    opacity: 0.5; /* Reduce opacity to indicate disabled state */
    cursor: not-allowed; /* Show not-allowed cursor for disabled state */
  }