// COLORS
$text-default-clr: #6A707E;
$ipd-main-text: #212529;
$ipd-primaryA: #1a3a7b;
$ipd-primaryB: #d23a2b;
$ipd-secondaryA: #14233f;
$ipd-secondaryB: #595959;
$ipd-secondaryC: #d9d9d9;
$ipd-secondaryD: #4eabe8;
$ipd-secondaryE: #e59c97;
$ipd-white: #ffffff;
$ipd-white2: rgb(255, 255, 255, 0.8);
$ipd-grey: #E4E7EB;
$vz-body-color: #1a3a7b;
$border-base:#E4E7EB;
$border-accordion-base:#e4e7eb;
$base-green:#3cab44;
$base-green-hvr:#26a32e;
$base-tick:#24b36a;
$base-background:#f9f9f9;
$base-link: #0a69df;
$hover-green:#e5fff0;
$hover-red:#ffe9e7;
$btn-font-size-md: 15px;
$ipd-bg-default:#f9f9f9;
$hover-btn-bg:#f3f3f9;
$status-do: #db5303;
$status-o: #c57415;
$status-g: #119e57;
$status-r: #c51547;
$status-b: #143b84;
$status-p: #821d81;
$status-gy: #afafaf;
$approval-alert-r: #d34160;
$text-danger: #d34160;
$input-border-clr: #E4E7EB;

// END COLORS

@mixin transition ($property, $duration, $timing-function) {
    transition: $property $duration $timing-function;
}

@mixin border-radius-full() {
    border-radius: 60px;
}

@mixin border-radius-default() {
    border-radius: 4px;
}