.main-content-container {
    display: flex;
    flex-wrap: wrap;
}

.main-content-container > * {
    flex: 1;
}

@media (max-width: 768px) {
    .main-content-container > * {
        flex: 100%;
    }
}

@media (max-width: 768px) {
    .main-container {
      flex-direction: column;
    }
}

.navbar-custom {
    background-color: #145193 ;
}

.button-singin {
    background-color: #09306b ;
    border-color:#09306b;    
}

.center-headings {
    background-color: #145193 ;
    padding-left: 700px;
}

.my-hidden-column {
    display: none;
}

