.labour-wrap-grid .k-tabstrip-content,
.labour-wrap-grid .k-tabstrip>.k-content{
    /*height: 420px;*/
    border-radius: 0;
}

.labour-base-grid {
   height: 387px;
}

/*handling each columns*/
.labour-base-grid .k-grid-table col, .labour-base-grid .k-grid-header col{
    width: 12.5%
}

.labour-base-grid .k-grid-table col:nth-of-type(1), .labour-base-grid .k-grid-header col:nth-of-type(1){
    width: 2%
}

.labour-base-grid .k-grid-table col:nth-of-type(2), .labour-base-grid .k-grid-header col:nth-of-type(2){
    width: 40%
}

.hideCol{
    visibility: collapse;
}

.labour-totalfooter{
    background-color: #E4E7EB;
    padding: 1em;
    height: 42px;
}

.labour-totalfooter .value{
   font-weight: 600;
}

