.center {
    position: absolute;
    top: 50%;
    left: 50%;
}

.padding-15 {
    padding: 15px;
}

.padding-10 {
    padding: 10px;
}

.editable {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 5px;
    background: #fff;
    color: #000;
}

.k-grouping-header {
    display: none !important;
}

/* .k-table-td {
    font-weight: 700 !important;
} */

.k-table-tbody {
    white-space: nowrap !important;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
