@import "../assets/default/scss/config/default/ipd-variable";

.ipln-workspace {
  background-color: $ipd-primaryA;
  padding: 1.1rem;
  color: $ipd-white2;
}

.ipln-system {
  bottom: 0;
  width: 100%;
  position: absolute;
  
  .inner {
    background-color: $ipd-primaryA;
    padding: 0.5rem 1rem;
    color: $ipd-white2;
  }

  .inner-txt{
    padding: 0.5rem 1rem;
    font-size: 12px;
  }
}

.ipln-workspc-container-SM{
  display: none;
}

/*Perfect scroll handling*/
.k-svg-i-chevron-down{
  transform: rotate(90deg);
}

.k-svg-i-chevron-up{
  transform: rotate(180deg);
}

.react-tooltip{
  z-index: 11500;
}

:is([data-layout=vertical], [data-layout=semibox])[data-sidebar-size=sm] .navbar-brand-box{
  z-index: 12000;
}

:is([data-layout=vertical], [data-layout=semibox])[data-sidebar-size=sm] .ipln-workspc-container{
  display: none; 
}

:is([data-layout=vertical], [data-layout=semibox])[data-sidebar-size=sm] .ipln-workspc-container-SM{
  display: inherit;
  border-top: 1px solid #eee;
}

:is([data-layout=vertical], [data-layout=semibox])[data-sidebar-size=sm] .ipln-system{
  display: none; 
}

.perfect-workspc-calc{
  height: calc(100% - 45px);
}

:is([data-layout=vertical], [data-layout=semibox])[data-sidebar-size=sm] .perfect-workspc-calc-SM{
  height: calc(100% - 70px);
  position: fixed;
}

.ps__rail-y {
  z-index: 11000;
}

.hidden-left-nav-bar {
  transform: translateX(-100%);
}

.expanded-left-nav-bar {
  width: 300px;
}

.library-node-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.button-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.main-node-title {
  font-weight: bold;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 3px;
}

.quote-container {
  display: flex;
  align-items: left;
  flex-direction: column;
}

.quote-container.library .collapse-lib-icon.ri-arrow-down-s-line{
 // display: none;
} 

.unit-names-container .panel-bar-item-link{
  display: block;
}

.unit-names-container .k-panelbar-item.k-level-undefined{
  display: inline-grid;
  width: 100%;
}

.unit-names-container .icon{
  margin-right: 5px;
}

.unit-names-container .collapse-lib-icon.ri-arrow-down-s-line{
  display: none;
}

.unit-name{
  display: flex;
}

.quote-title {
  flex-grow: 1;
  margin-right: 5px;
}

.delete-quote-icon {
  position: relative;
  float: right;
  background-color: #C0D4F1;
  text-align: center;
  border-radius: 60px;
  width: 26px;
  height: 26px;
}

.close-quote-icon {
  position: relative;
  float: right;
  background-color: #C0D4F1;
  text-align: center;
  border-radius: 60px;
  width: 26px;
  height: 26px;
}

.close-quote-icon.linkedunit {
  background-color: #c3f1c0;
}

.collapse-lib-icon {
  position: relative;
  float: right;
  background-color: transparent;
  text-align: center;
  border-radius: 60px;
  border: transparent;
  font-weight: bolder;
  width: 26px;
  height: 26px;
}

/*panel bar menu*/
.panel-bar-item-link {
  text-decoration: none;
  color: inherit;
}

.panel-bar-item-link:hover {
  color: $ipd-primaryB;
  /* Color when hovering */
  text-decoration: solid;
}

.panel-bar-item-link.active{
  color: $ipd-primaryA;
  background-color: #edf5ff;
  font-weight: 600;
  display: block;
}

.panel-bar-item-link.active.linkedunit{
  background-color: #eaf9e9;
}

.selected-item {
  background-color: #e02f4d;
}

.sub-box {
  padding: 10px 15px 10px 25px;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.sub-box .icon {
  margin-right: 10px;
}

.sub-box.leveltwo{
  padding: 10px 0 10px 30px;
}

.quote-container.library .sub-box {
  padding: 10px 0px 10px 25px;
}

.quote-header-box {
  justify-items: flex-start;
  padding-inline: 50px;
  position: relative;

}

/*Left Navigation button handling*/
.k-panelbar-content.k-content .button-container{
  visibility: hidden;
}

.k-panelbar-content.k-content:hover .button-container {
  visibility: visible;
}

.k-panelbar .k-group > .k-item.k-level-1 .k-link, .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link .button-container {
  visibility: hidden;
}

.k-panelbar .k-group > .k-item.k-level-1 .k-link:hover, .k-panelbar .k-panelbar-group:hover > .k-panelbar-item.k-level-1 .k-link:hover .button-container {
  visibility: visible;
}

.k-panelbar .k-group > .k-item.k-selected .k-level-1 .k-link.k-selected, .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link.k-selected .button-container {
  visibility: visible;
}

.panel-bar-item-link.active .button-container {
  visibility: visible !important;
}

/*Panel Small*/
.ipln-panelbar-SM{
  width: 60px;
}
.ipln-panelbar-SM > .k-panelbar-header > .k-link{
  padding: 12px 15px 20px 24px;
}

.ipln-panelbar-SM .sub-box {
  padding: 0px 15px 0px 25px;
}

.ipln-panelbar-SM .ipln-panelitem-container-sm {
  padding-block: 0.8rem;
}

.ipln-panelitem-container-sm{
  display: flex;
  align-items: left;
}

.ipln-panelbar-SM .k-svg-icon {
  position: absolute;
  top: 60px;
  right: 24px;
  width: 12px;
  height: 12px;
}

.ipln-Title-SM{
  margin-top: 5px;
  font-weight: 600;
  margin-left: -8px;
  font-size: 11px;
}

.ipln-disabledlook{
  filter: grayscale(1);
  opacity: 0.5;
}