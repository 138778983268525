.imp-dialog-section {
    border: 1px solid #ddd;  /* Border color */
    padding: 15px 20px;  /* Inner spacing */
    margin-bottom: 10px;  /* Space between sections */
    border-radius: 4px;  /* Rounded corners */
}

/* Optional: Add some style to the file input */
input[type="file"] {
    width: 100%;
    padding: 5px;
}

/* Optional: Style for your headings */
h6 {
    margin-bottom: 10px;
    color: #333;  /* Text color */
}
