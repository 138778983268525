// Compatible with @progress/kendo-theme-bootstrap v.6.4.0


$tb-kendo-is-dark-theme: false;
$tb-kendo-border-radius: 3px;
$tb-kendo-color-primary: #1a3a7b;
$tb-kendo-color-secondary: #595959;
$tb-kendo-color-success: #198754;
$tb-kendo-color-info: #4eabe8;
$tb-kendo-color-warning: #ffc107;
$tb-kendo-color-danger: #dc3545;
$tb-kendo-body-bg: #ffffffff;
$tb-kendo-body-text: #212529;
$tb-kendo-subtle-text: #6c757d;
$tb-kendo-disabled-text: #adb5bd;
$tb-kendo-base-bg: #f8f9fa;
$tb-kendo-hover-bg: #e9ecef;
$tb-kendo-selected-text: #ffffff;
$tb-kendo-button-bg: #ffffffff;
$tb-kendo-button-text: #212529;
$tb-kendo-link-hover-text: #0a58ca;
$tb-kendo-series-b: #6f42c1;
$tb-kendo-series-c: #20c997;
$tb-kendo-series-d: #198754;
$tb-kendo-series-e: #ffc107;
$tb-kendo-series-f: #dc3545;
$tb-kendo-component-bg: $tb-kendo-body-bg;
$tb-kendo-component-text: $tb-kendo-body-text;
$tb-kendo-base-text: $tb-kendo-body-text;
$tb-kendo-hover-text: $tb-kendo-base-text;
$tb-kendo-selected-bg: $tb-kendo-color-primary;
$tb-kendo-link-text: $tb-kendo-color-primary;
$tb-kendo-series-a: $tb-kendo-color-primary;


$kendo-is-dark-theme: $tb-kendo-is-dark-theme;
$kendo-border-radius: $tb-kendo-border-radius;
$kendo-color-primary: $tb-kendo-color-primary;
$kendo-color-secondary: $tb-kendo-color-secondary;
$kendo-color-success: $tb-kendo-color-success;
$kendo-color-info: $tb-kendo-color-info;
$kendo-color-warning: $tb-kendo-color-warning;
$kendo-color-danger: $tb-kendo-color-danger;
$kendo-body-bg: $tb-kendo-body-bg;
$kendo-body-text: $tb-kendo-body-text;
$kendo-subtle-text: $tb-kendo-subtle-text;
$kendo-disabled-text: $tb-kendo-disabled-text;
$kendo-base-bg: $tb-kendo-base-bg;
$kendo-hover-bg: $tb-kendo-hover-bg;
$kendo-selected-text: $tb-kendo-selected-text;
$kendo-button-bg: $tb-kendo-button-bg;
$kendo-button-text: $tb-kendo-button-text;
$kendo-link-hover-text: $tb-kendo-link-hover-text;
$kendo-series-b: $tb-kendo-series-b;
$kendo-series-c: $tb-kendo-series-c;
$kendo-series-d: $tb-kendo-series-d;
$kendo-series-e: $tb-kendo-series-e;
$kendo-series-f: $tb-kendo-series-f;
$kendo-component-bg: $tb-kendo-component-bg;
$kendo-component-text: $tb-kendo-component-text;
$kendo-base-text: $tb-kendo-base-text;
$kendo-hover-text: $tb-kendo-hover-text;
$kendo-selected-bg: $tb-kendo-selected-bg;
$kendo-link-text: $tb-kendo-link-text;
$kendo-series-a: $tb-kendo-series-a;
    $kendo-font-family: Poppins-Regular;
    $kendo-font-size: 12px;
    $kendo-font-weight-normal: 400;
    $kendo-line-height: 1.5;
    
$enable-gradients: true;

$tb-typography: (
  kendo-default-typography: (
      font-family: 'Poppins-Regular',
      font-size: 12px,
      font-weight: 400,
      line-height: 1.5,
  ),
  poppins-bold: (
      font-family: 'Poppins-Bold',
      font-size: 12px,
      font-weight: 600,
      line-height: 1,
  ),
);

@mixin typography-classes ($typography) {
  @each $selector, $property in $typography {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: #{$propValue};
      }
    }
  }
}

$tb-effects: (
  tb-internal-none-effects: (
      box-shadow: (none), filter: (none), backdrop-filter: (none),  ),
);

@mixin effects-classes ($effects) {
  @each $selector, $property in $effects {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue;
      }
    }
  }
}