/*PDF*/
@font-face {
    font-family: "Poppins-B";
    font-weight: 700;
    src: url("../../fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-SM";
    font-style: 600;
    src: url("../../fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-SMI";
    font-style: italic;
    font-weight: 600;
    src: url("../../fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins";
    font-weight: 400;
    src: url("../../fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-weight: normal;
    src: url("../../fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Bold";
    font-weight: 600;
    src: url("../../fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-BoldItalic";
    font-weight: 600;
    font-style: italic;
    src: url("../../fonts/Roboto-BoldItalic.ttf") format("truetype");
}

.pdf-table{
    font-family: 'Poppins', sans-serif;
    font-size: 9px;
    font-weight: 400;
    line-height: 1;
    padding: 2.5rem 5rem;
    width: 100%;
    margin-bottom: -2.5rem;
}

.pdf-table p{
    line-height: 0rem;
    font-size: 9px;
}

.pdf-company .r1 p{
    margin-top: 1.5rem;
    font-size: 13px;
    font-weight: 700;
    font-family: 'Poppins-B', sans-serif;
}

.pdf-quotehead{
    font-size: 16px;
    color: #1a3a7b;
    font-weight: 700;
    font-family: 'Poppins-B', sans-serif;
    margin-bottom: 20px;
    margin-top: -3rem;
    text-transform: uppercase;
    margin-left: 0px;
}

.pdf-quotetitle{
    font-size: 10px;
    width: 100%;
}

.pdf-quotetitle table {
    width: 100%;
}

.pdf-quotetitle td{
  
}

.pdf-advertimg{
    margin-top: -2rem;
}

.pdf-contact{
    float: left;
    padding-top: 8px;
    margin-bottom: 5px;
}

.pdf-contact .seperator{
    padding-left: 5px;
    padding-right: 5px;
}

.pdf-head-grandtotal{
    float: right;
    font-family: 'Poppins-SM', sans-serif;
    font-weight: 600;
    border:1px solid #1a3a7b;
    padding: 5px 10px;
    margin-bottom: 5px;
}

.pdf-head-grandtotal span{
    margin-left: 3px;
}

.pdf-highlight{
    color: #d23a2b;
}

.pdf-address{
    margin-top: 2rem;
    font-size: 9px;
}

.pdf-quotetable{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 8px;
    line-height: 1.1;
    padding: 2.5rem 5rem;
    width: 100%;
    /*border: 1px solid #CDD0D7;*/
    /*border-collapse: collapse;*/
    margin-top: 2rem;
}

.pdf-quotetable p{
    line-height: 0.25rem;
}

.pdf-quotetable th, .pdf-quotetable td{
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
}

.pdf-quotetable .r-head-spacer{
    height: 5px;
}

.pdf-quotetable .r-head{
    border-top: 1px solid #1a3a7b;
    background-color: #f5f6f9;
    font-family: 'Poppins-SM', sans-serif;
    font-size: 9px;
}

.pdf-quotetable .r-head td{
    padding-top: 6px;
    padding-bottom: 5px;
    vertical-align: middle;
}

.pdf-quotetable .r-head.grand{
    border-bottom: 1px double #1a3a7b;
    margin-bottom: 10px;
    vertical-align: middle;
}

.pdf-grandtotal{
    font-family: 'Poppins-B', sans-serif;
    font-weight: bold;
    font-size: 9px;
}

.pdf-group-title td{
    font-family: 'Poppins', sans-serif;
    font-size: 8px;
    padding-top: 5px;
    padding-bottom: 2px;
    text-align: right;
}

.pdf-group-title .c1{
    font-weight: bold;
}

.pdf-group-title .c1.left{
    text-align: left;
}

.pdf-subgroup-title td{
    font-family: 'Poppins', sans-serif;
    font-size: 8px;
    padding-top: 5px;
    text-align: right;
}

.pdf-subgroup-title .c1{
    font-style: italic;
    font-weight: bold;
}

.pdf-subgroup-title .c1.left{
    text-align: left;
}

.pdf-group-title .numfrend, .pdf-subgroup-title .numfrend, .pdf-normal-title .numfrend {
    font-family: 'Roboto', sans-serif;
}

.pdf-group-title .numfrendbold, .pdf-subgroup-title .numfrendbold, .pdf-normal-title .numfrendbold {
    font-family: 'Roboto-Bold', sans-serif;
}

.pdf-group-title .numfrendboldi, .pdf-subgroup-title .numfrendboldi, .pdf-normal-title .numfrendboldi {
    font-family: 'Roboto-BoldItalic', sans-serif;
}

.pdf-group-title .numfrend.left, .pdf-subgroup-title .numfrend.left, .pdf-normal-title .numfrend.left {
    text-align: left;
}

.pdf-header {
    font-family: 'Poppins', sans-serif;
    font-size: 8px; 
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    position: absolute;
    top: 0;
    width: 88%;
}

.pdf-header .r1line{
    border-top: 1px solid #1a3a7b;
    margin-bottom: 10px;
}

.pdf-stamp {
    font-family: 'Poppins', sans-serif;
    font-size: 8px; 
    position: absolute;
    top: 35%;
    width: 100%;
    left: 30%;
}

.pdf-footer {
    font-family: 'Poppins', sans-serif;
    font-size: 8px; 
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    position: absolute;
    bottom: 0;
    width: 88%;
}
.pdf-footer .r1-c1{
    font-size: 7px;
    color: #6e6e6e;	
}
.pdf-footer .r2-c1{
    float: left;
    font-size: 7px;
    color: #6e6e6e;	
}
.pdf-footer .r2-c2{
    float: right;
    font-size: 8px;     
}
.pdf-footer .r2{
    margin-top: 10px;
}
.pdf-footer .r1line{
    border-top: 1px solid #1a3a7b;
    margin-bottom: 10px;
}
.pdf-note{
    font-family: 'Poppins', sans-serif;
    font-size: 7px;
    width: 100%;
   
}

.pdf-note span {
    font-weight: bold;
}

.pdf-note-row{
    height: 20px;
}

.pdf-quotetable-item{
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
}
.pdf-fbold{
    font-family: 'Poppins-SM', sans-serif;
    font-weight: 600;
}
.pdf-fbolder{
    font-family: 'Poppins-B', sans-serif;
    font-weight: bold;
}

.pdf-cutomerto{
    margin-top: 1rem;
}
.pdf-cutomerto p{
   font-size: 10px;
}
.pdf-project{
    margin-bottom: 8px;
}

/*Terms*/
.pdf-terms{
    font-family: 'Poppins', sans-serif;
    font-size: 9px;
    font-weight: 400;
    line-height: 1.25;
    width: 100%;
    padding: 1rem 0 0 0;
    margin-top: 1.5rem;
    display: table;
}

.pdf-terms.trading{
    display:table;
    line-height: 1.5;
    margin-top: -2rem !important;
    padding-bottom: 6em !important;
}

.pdf-terms.trading .heading2{
    padding-left: 0.5rem;
    padding-top:0.2rem;
}

.pdf-terms.trading .heading2 .h1{
    margin-bottom: 0.2rem;
    font-weight: bold;
    font-size: 11px;
    color: #1a3a7b;
    text-transform: uppercase;
}

.pdf-terms.trading .heading2 .b1{
   
}

.pdf-terms .heading{
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    padding-top:0.2rem;
    font-weight: bold;
    font-size: 11px;
    color: #1a3a7b;
    text-transform: uppercase;
}

.pdf-terms strong{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: #2a2a2a;
    margin-bottom: 5px;
}

.pdf-terms p{
    line-height: 14px;
    margin-bottom: 9px;
   
}

.pdf-terms ol{
   margin-left: -2rem;
}

.pdf-bodyadj{
    display: table;
    padding-left: 0.5rem;
    padding-bottom: 3rem;
}

.displaynone {
    display: none;
}

.displaynoneimp {
    display: none !important;
}

.pdf-terms-inner{
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.2;
    width: 100%;
    padding: 0.2rem 0;
}

.pdf-terms-inner .heading{
    color:  #1a3a7b;
    font-weight: bold;
    font-size: 9px;
    margin-left: -0.5rem;
    margin-bottom: 5px;
}

.pdf-terms-inner .body{
    font-size: 9px;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
}
