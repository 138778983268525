@import "ipd-variable";

body {
    * {
        outline: none;
    }
}

body {
    background-color: #f0f0f0;
}

/*Progress Bar*/
.bar-txt {
    font-size: 11px;
    margin-bottom: 2px;
}

.bar-progress-label {
    font-size: 11px;
    color: #FFF;
    font-weight: bold;
    padding-top: 1px;
    margin-right: 5px;
}

.importbar-progress-wrap {
    padding: 7px 20px 9px 20px;
    /*background-color: #e3eaf1;*/
    border-bottom: 1px solid #ddd;
}

/*Boostrap overwrites*/
.btn {
    --vz-btn-padding-x: 0.75rem;
}

.btn:disabled,
.btn.disabled {
    border: none;
    opacity: 0.4;
}

.btn:disabled.quote-another-person,
.btn.disabled.quote-another-person {
    pointer-events: auto;
    opacity: 0.3;
}

.text-danger {
    color: $text-danger !important;
}

.align-right {
    text-align: right !important;
}

/*Velzone overwrites*/
@media (max-height: 700px) {
    .topbar-user.topbar-quote .dropdown-menu {
        overflow-y: auto;
        height: calc(100dvh - 100px);
    }
}

/*ipd related*/
.ip-topbar-headtxt {
    margin: 2px 8px;
    color: $text-default-clr;
}

.btn-ipd {
    font-size: 10px;
    --vz-btn-line-height: 1;
    height: 32px;
}

.ip-line-seperator {
    height: auto;
    border-left: 1px solid #e1e1e1;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 6px 10px;
}

.ip-icon-size-lg {
    font-size: 20px;
}

.ip-icon-size-lg2 {
    font-size: 24px;
}

.ip-icon-size-lg.red:hover {
    color: $ipd-primaryB;
}

.ip-icon-size-lg.blue:hover {
    color: $ipd-secondaryD;
}

.ip-icon-size-lg2.blue:hover {
    color: $ipd-secondaryD;
}

.ip-action-ico {
    background-image: url("../../../images/ipd/ipd-sm-icon.png");
    background-size: 20px auto;
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
}

.ip-sub-h1 {
    font-size: 16px;
    color: $ipd-primaryA;
    margin-top: 0.5rem;
    display: inline-block;
}

.ip-secondarymenu-btn {
    border: 0;
    font-size: 13px;
}

.ip-secondarymenu-btn:hover {
    background-color: $hover-btn-bg;
}


.ip-subhead-btn {
    position: absolute;
    top: 0;
}

.ip-submenu-show-total {}

.ip-item-summary {
    background-color: $ipd-grey;
    padding: 1em;
    margin-top: -0.35rem !important;
    border-top: 1px solid #d0d5e0;
    box-shadow: 1px 1px 10px #b9b9b9;
    display: flex;
    justify-content: space-between;
}

.ip-item-calc-r {
    max-width: 600px;
}

.ip-whse-summary {
    background-color: $ipd-grey;
    padding: 1em;
    border: 1px solid #d0d5e0;
}


.ip-btn-withicon {
    background-color: transparent !important;
}

.ip-bg-overlay {
    background-color: rgba(20, 35, 63, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
}

.ip-bg-overlay-pad .carousel.slide {
    width: 90%;
    margin: auto;
    margin-bottom: 30px;
}

.ip-subnode-container {
    position: relative;
    height: 100%;
    margin-top: 32px;
    margin-bottom: 10px;
    padding: 5px;
}

.options-container {
    height: calc(100vh - 160px);
    margin-top: 35px;
    padding: 5px;
}

.ip-add-btn {
    @include border-radius-full();
    background-color: $base-green;
    color: #fff;
    border: 0;
    height: 24px;
    width: 24px;
}

.ip-add-btn span::before {
    color: $ipd-white;
}

.ip-add-btn:hover {
    background-color: $base-green-hvr;
}

.ip-font-btn {
    font-size: $btn-font-size-md;
}

.ip-item-view-btn-kbutton {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-left: 5px;
}

.ip-item-view-btn-kbutton:hover {
    border-color: transparent;
    background-color: transparent;
}

.ip-item-view-btn {
    display: block;
}

.ip-item-view-btn span {
    margin-right: 5px;
}

.ip-item-view-btn .k-picker {
    margin-right: 0px;
}

.ip-item-view-btn>.k-picker {
    width: auto;
}

.ip-item-view-btn.grid-hscroller span {
    margin-right: 0px;
    padding-right: 0px;
    margin-left: 0;
}

.ip-item-view-btn.grid-hscroller .ip-item-view-btn.grid-hscroller {
    padding-inline: 0.1rem;
}

.ip-item-view-btn.grid-hscroller .k-picker {
    margin-left: -5px;
}

.ip-tick-show {
    font-size: 15px;
    cursor: pointer;
}

.form-check-input:checked.ip-tick-show {
    border-color: $ipd-primaryB;
    background-color: $ipd-primaryB;
}

.ip-copy-show {
    font-size: 15px;
    cursor: pointer;
    margin: 7px;
    border: 0;
}

.form-check-input:checked.ip-copy-show {
    border-color: $ipd-primaryB;
    background-color: $ipd-primaryB;
}

.ip-SOHsub-check-show {
    font-size: 15px;
    cursor: pointer;
    border: 0;
    margin-top: 0;
}

.form-check-input:checked.ip-SOHsub-check-show {
    border-color: $ipd-primaryB;
    background-color: $ipd-primaryB;
}

.ip-SOHsub-select {
    height: 32px;
    line-height: 1.2;
    border-color: $input-border-clr;
    min-width: 200px;
}

.ip-SOHsub-select:hover {
    background-color: #eee;

}

.ip_search_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 50%;
}

.ip_labour_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 50%;
}

.ip-special-alert {
    margin: 0rem 0rem 0rem 0.7rem;
    width: calc(100% - 1.4rem);
    display: flex;
    align-items: center;
    align-content: center;
    background-color: #ffeeed;
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    border-left: 4px solid #f06548;
    border-top: 0;
    border-right: 1px solid #ddd;
    box-shadow: 1px 1px 5px #ddd;
    font-size: 12px;
}

.ip-special-alert.amber {
    margin: 0rem 0rem 0rem 0.7rem;
    width: calc(100% - 1.4rem);
    display: flex;
    align-items: center;
    align-content: center;
    background-color: #fffced;
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    border-left: 4px solid #f0b548;
    border-top: 0;
    border-right: 1px solid #ddd;
    box-shadow: 1px 1px 5px #ddd;
}

.ip-special-alert.rowone {
    margin-bottom: 8px !important;
}

.ip-special-alert.rowtwo {
    width: 100%;
    margin: 0;
    border-radius: 0;
    border: 0;
    border-left: 4px solid #f06548;
    font-size: 12px;
}

.ip-special-alert button {
    animation: fadeInOut 2s ease-in-out infinite;
    line-height: 15px;
    font-size: 12px;
}

@keyframes fadeInOut {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

/*Quote Lookup*/
.ql-draftquote-note {
    color: #5482a9;
    font-size: 12px;
    position: absolute;
    right: 17px;
}

.ql-draftquote-note i {
    margin-right: 5px;
}

.qnum-tooltipdiv {
    font-size: 11px;
    font-weight: 400;
    color: #c0c0c0;
}

.qnum-tooltipdiv span {
    color: #FFF;
}

/*Units Page*/
.ip-unit-view-btn {
    margin: -8px -13px;
    background-color: #f8f9fa;
    display: block;
}

.ip-unit-view-btn>span {
    margin-right: 5px;
    margin-left: 5px;
}

.ip-unit-view-btn>.k-picker {
    width: auto;
}

/*Price Page*/
.ip-price-view-btn {
    margin: -8px -13px;
    background-color: #f8f9fa;
    display: block;
}

.ip-price-view-btn>span {
    margin-right: 5px;
    margin-left: 5px;
}

.ip-price-view-btn>.k-picker {
    width: auto;
}

/*Parts View*/
.ip-p-search {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 50%;
}

.ip-p-side-by-side {
    display: inline-block;

}

.ip-p-side-by-side.radio {
    vertical-align: top;
}

.ip-p-side-by-side.image {
    margin-top: -10px;
}

.ip-p-note {
    color: #0859ef;
    margin-top: 5px;
    font-size: 11px;
}

.ip-p-actionradio li:nth-child(3) {
    color: #0859ef;
}

.ip-p-partdelete {
    text-decoration: line-through;
    color: red;
}

/*Grid Handling*/
.k-grid-pager.k-focus {
    box-shadow: inset 0 0 0 2px rgb(0 31 196 / 53%) !important;
}

.ip-editable-input,
.ip-editable-input input.k-input {
    width: 100%;
    border: 1px solid transparent;
    @include transition(all, 0.3s, ease-in-out);
    color: inherit !important;
    background-color: transparent;
}

.k-input, .k-picker{
    font-size: inherit;
}

.k-grid td > input.k-input {
    height: 16px;
    padding: 1px;
}

.zeroVal {
    color: red !important;
}

.ip-editable-input:enabled,
input.k-input:enabled {
    background-color: white !important;
}

.ip-editable-input:hover,
input.k-input:hover {
    border: 1px solid $ipd-secondaryD;
    background-color: #ebf6ff !important
}

.ip-editable-input:focus,
input.k-input:focus {
    border: 1px solid $ipd-secondaryD;
    border-style: solid;
    @include transition(all, 0.3s, ease-in-out);
    background-color: white;
}

.ip-hover-link u:hover {
    color: $ipd-primaryB;
}

.ip-editable-input-always,
input.k-input-always {
    width: 100%;
    border: 1px solid $ipd-secondaryC;
    @include transition(all, 0.3s, ease-in-out);
}

.ip-editable-input-always:focus,
input.k-input-always:focus {
    border: 1px solid $ipd-secondaryD;
    border-style: solid;
}

//Grid Icon
.ip-gridclr-dbextra {
    color: #06b1b1 !important;
}

.ip-gridclr-assembly {
    color: #0c8f28 !important;
}

.ip-gridclr-rep {}

tr.ip-grid-caution>td:nth-child(3) {
    // will be used as an identification to placed an icon on the grid
    background: no-repeat center/17px URL('/src/assets/default/images/ipd/grid/caution2.png');
}

.ip-grid-caution>td:nth-child(3) {
    // will be used as an identification to placed an icon on the grid
    background: no-repeat center/17px URL('/src/assets/default/images/ipd/grid/caution2.png');
}

.right-align {
    text-align: right;
}

.ip-gridclr-note {
    color: #9b5195 !important;
}

tr.ip-gridclr-note>td:nth-child(3) {
    background: no-repeat center/16px URL('/src/assets/default/images/ipd/grid/note-book-2.png');
}

.ip-gridclr-custom {
    color: #012cc9 !important;
}

.ip-gridclr-listprice {}

tr.ip-gridclr-no-listprice>td:nth-child(3) {
    background: no-repeat center/17px URL('/src/assets/default/images/ipd/grid/warning.png');
}

tr.ip-gridclr-notif-repdisc>td:nth-child(3) {
    background: no-repeat center/17px URL('/src/assets/default/images/ipd/grid/increase.png');
}

.ip-gridclr-noneditable {
    background-color: #f7f7f7;
    text-align: right !important;
}

.ip-editable-input.ip-gridclr-costea {
    background-color: #ffeded !important;
    text-align: right !important;
}

.ip-editable-input.ip-gridclr-dc {
    background-color: #e4f5ea !important;
    text-align: right !important;
}

td.ip-gridclr-costea {
    background-color: #ffeded !important;
    text-align: right !important;
}

td.ip-gridclr-dc {
    background-color: #e4f5ea !important;
    text-align: right !important;
}

.ip-gridclr-cross {
    color: #f3980f;
    content: 'x';
}


/* Combining both styles */
tr.ip-gridclr-cross.ip-grid-caution>td:nth-child(3) {
    background: no-repeat center/17px URL('/src/assets/default/images/ipd/grid/caution2.png');
    color: #f3980f;
    position: relative;
}

.ip-gridclr-diffstock {
    color: #012cc9 !important;
}

.ip-gridclr-notvalid {
    color: #012cc9 !important;
    text-decoration: line-through !important;
}

.ip-gridclr-notvalid>td>input {
    text-decoration: line-through !important;
}

.ip-gridclr-notvalid>td:nth-child(3) {
    // will be used as an identification to placed an icon on the grid
    background: no-repeat center/17px URL('/src/assets/default/images/ipd/grid/caution2.png');
}

.k-treelist-toggle {
    font-size: .5rem;
}

/*Import log dialog*/
.ip-importlog {
    white-space: pre-line;
    font-size: 11px;
    color: #152749;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: -15px;
}

/*handles the grid in the dialog boxes*/
.ip-dialog-grid-container .ip-base-grid {
    min-height: 500px;
    max-height: 500px;
}

.ip-dialog-grid-container .ip-selector-gridsize {
    flex: 3;
}

.ip-dialog-grid-container .ip-adder-gridsize {
    flex: 1;
}

.ip-dialog-grid-container .k-grid-header col:not(:first-of-type), .ip-dialog-grid-container .k-grid-table col:not(:first-of-type) {
    min-width: 100px
}


/*handles the grid related items in the main subnode and base grid*/
.ip-subnode-container .ip-base-grid {
    //max-height: 300px;
    border-width: 0;
}

.ip-subnode-container .ip-base-grid .k-pager.k-pager-md.k-grid-pager {
    display: none;
}

.ip-subnode-container .ip-itembase-grid {
    //max-height: 300px;
    border-width: 0;
}

.ip-subnode-container .grid-header-box {
    display: none;
}

.ip-subnode-container .ip-base-grid .k-pager.k-pager-md.k-grid-pager {
    display: none;
}

/*handles the grid related items in the main subnode and base grid relating to the pricing menu*/
.ip-subnode-container.price-lists .ip-base-grid .k-pager.k-pager-md.k-grid-pager {
    display: inline-flex;
}

/*handles the grid related items in the main subnode and base grid relating to the Units menu*/
.ip-subnode-container.new-units .ip-base-grid .k-pager.k-pager-md.k-grid-pager {
    display: inline-flex;
}


.ip-base-grid .k-link {
    font-size: 10px;
    font-weight: 600;
}

.ip-base-grid-noneditable {
    /*background-color: #f7f7f7;*/
}

/*Grouping grid*/
.ip-grouprow-style {
    font-weight: bold;
    font-family: 'Poppins-B';
    background-color: #f0f3f7;
}

.ip-grouprow-style .ip-editable-input {
    font-weight: bold;
}

.ip-subgrouprow-style {
    font-family: 'Poppins-SM';
}

.groupHeader {
    color: $ipd-secondaryA;
    font-weight: bold;
    background: linear-gradient(90deg, #e3e7ef, #ffffff);
}

.groupHeader p.k-reset {
    margin: 4px;
    letter-spacing: 0px;
    font-size: 11px;
}

/*Item Grid - item column handling, please comment out when the grid resize lock structure is implemented*/
/*.ip-itembase-grid col:nth-of-type(1){
    text-align:center;
}
.ip-itembase-grid col:nth-of-type(6){
    width: 180px
}
.ip-itembase-grid col:nth-of-type(7){
    width: 150px
}*/

.ip-itembase-grid .k-link {
    font-size: 10px;
    font-weight: 600;
}

.ip-itembase-grid .k-table-md .k-table-th {
    font-size: 11px;
}

.ip-itembase-grid td {
    font-size: 11px;
}

.ip-itembase-grid .ip-editable-input {
    font-size: 11px;
}

/*Item Grid handling border line - production*/
.ip-itembase-grid .k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td {
    border-left: 1px solid #dee2e6;
}

.ip-itembase-grid.k-grid td,
.ip-itembase-grid.k-grid .k-table-td {
    padding-block: 0.1rem;
    font-size: 11px;
}

.ip-itembase-grid .k-table-md .k-table-th {
    border-left: 1px solid #ddd;
    padding-block: 0.4rem;
}

/*Tree Quote Item Grid*/
.ip-quote-item-grid {
    border: 0;
}

/*handles the grid selected row on select of the checkbox in the entire app*/
.selected-row {
    background-color: #d9ecf9 !important;
}

tr.selected-row>td>.ip-editable-input {
    background-color: #d9ecf9 !important;
}

tr.selected-row>td.ip-gridclr-noneditable {
    background-color: #d9ecf9 !important;
}

.arrowRightHighlight {
    background-color: #d9e8f7 !important;
}

tr.arrowRightHighlight>td.ip-gridclr-noneditable {
    background-color: #d9e8f7 !important;
}

tr.arrowRightHighlight>td.ip-editable-input {
    background-color: #FFF !important;
}

.arrowRight {
    background: no-repeat center/9px URL('/src/assets/default/images/ipd/grid/right-arrow.png');
    opacity: 0.7;
}

.ip-firsttime-itemgrid {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    max-height: 200px;
}

.ip-firsttime-itemgrid span:hover {
    cursor: pointer;
    color: $ipd-primaryA;
}

/*Lookup handling*/

.ipl-button-set {
    margin: 30px 20px;
}

.ipl-button-add:hover {
    background-color: $hover-green;
}

.ipl-button-remove:hover {
    background-color: $hover-red;
}

.ip-button-general:hover {
    background-color: $ipd-primaryA;
    color: $ipd-white;
}

.ip-button-positive:hover {
    background-color: #21b950;
    color: $ipd-white;
}

.ip-button-negative:hover {
    background-color: #ce2d2d;
    color: $ipd-white;
}

.ip-set-pricemargin {
    width: 100%;
}

.ip-set-pricemargin th,
.ip-set-pricemargin td {
    padding: 8px 5px;
}

td:focus[contenteditable="true"] {
    @include transition(all, 0.3s, ease-in-out);
    background-color: white;
    box-shadow: inset 0 0 0 1px $ipd-secondaryD;
}

td[contenteditable="false"] {
    background-color: inherit;
}

/*Grid Handling*/
.part-consolidated-grid .ip-base-grid {
    height: calc(100vh - 450px);
}

/*authentication page*/
.auth-page-wrapper .auth-page-content {
    padding-bottom: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
}

.ip-login-footer {
    position: fixed;
    bottom: 10px;
    right: 20px;
}

/*modification of app*/
.displaynone {
    display: none;
}

.displaynoneimp {
    display: none !important;
}

.btn-label {
    padding-left: 32px;
}

.navbar-header {
    padding: 0;
    height: 48px;
}

.navbar-menu {
    border-right: 0;
}

.page-content {
    padding: calc(48px + 1.5rem) calc(1.5rem * 0.5) 0px calc(1.5rem * 0.5);
}

.header-item {
    height: 100%;
}

.dropdown-item {
    color: $ipd-secondaryA;
}

.dropdown-item:hover {
    color: $ipd-primaryA;
}

.page-title-box {
    position: fixed;
    z-index: 10;
    width: calc(100% - $vertical-menu-width + 2px);
    padding: 0px 5px 0px 10px;
    margin: -23px -0.5rem 0rem -0.8rem;
    height: 50px;
}

hr {
    margin: 0.5rem 0;
}

[data-sidebar-size="sm"] {
    .page-title-box {
        width: calc(100% - $vertical-menu-width-sm);
    }

    @media (max-width: 991.98px) {
        .page-title-box {
            width: 100%;
        }
    }
}

@media (max-width: 768px) {
    .page-title-box {
        width: 100%;
    }
}


.footer {
    padding: 11px calc(1.5rem * 0.5);
    height: 35px;
}

/*Accordion*/
.ip-accord-button {
    position: absolute;
    top: 8px;
    left: 6em;
    z-index: 10;
}

.accordion-header {
    line-height: 1;
}

.accordion-button {
    background-color: $ipd-secondaryA;
    color: $ipd-grey;
}

.accordion-button:not(.collapsed) {
    background-color: $ipd-secondaryA;
    color: $ipd-grey;
}

.accordion-button::after {
    color: $ipd-grey;
}

.accordion {
    --vz-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='512' height='512' x='0' y='0' viewBox='0 0 128 128' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cpath d='M64 88a3.988 3.988 0 0 1-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0L64 78.344l37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40A3.988 3.988 0 0 1 64 88z' fill='%23ffffff' data-original='%23000000' class='' opacity='1'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    --vz-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='512' height='512' x='0' y='0' viewBox='0 0 128 128' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cpath d='M64 88a3.988 3.988 0 0 1-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0L64 78.344l37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40A3.988 3.988 0 0 1 64 88z' fill='%23ffffff' data-original='%23000000' class='' opacity='1'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    --vz-accordion-border-color: #e4e7eb;
}

.accordion-body {
    padding: 0;
    /*min-height: 150px;*/
}

.testbutton {
    position: absolute;
    right: 15%;
    z-index: 5000;
    width: auto;
    top: 5px;
}

/*intro workspace*/
.ip-introworkspace {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: calc(100vh - 120px);
}

.ip-introworkspace>h3 {
    color: $ipd-primaryA;
    font-size: 1.2rem;
}

/*Dialog*/
.ipd-dialog-container>.k-dialog {
    min-height: 600px;
    width: 100%;
}

.ipd-dialog-container-approval>.k-dialog {
    width: 50%;
}

.ipd-dialog-in-dialog-container>.k-dialog {
    min-height: 600px;
    width: 50%;
}

.ip-dialog-container-semi .k-dialog {
    width: 40%;
    min-width: 600px;
}

.ip-dialog-container-semi .k-dialog .ip-loader {
    width: 88%;
}

.ip-dialog-btnstatic {
    padding: 15px 25px 15px 25px;
    border-top: 1px solid $border-base;
    background-color: #f9f9f9;
}

.ip-dialog-btnstatic-f2 {
    padding: 15px 0px 15px 0px;
    background-color: #f9f9f9;
}

/*Dialog specifics container*/
.reset-quote-container {
    max-width: 600px;
}

.search-quote-contact-container .k-pager-numbers-wrap select.k-dropdown-list,
.k-pager-numbers-wrap select.k-dropdown,
.k-pager-numbers-wrap select.k-dropdownlist {
    display: none;
}

.k-pager-md .k-pager-numbers-wrap .k-pager-input, .k-pager-md .k-pager-numbers-wrap > select.k-dropdown-list, .k-pager-md .k-pager-numbers-wrap > select.k-dropdown, .k-pager-md .k-pager-numbers-wrap > select.k-dropdownlist{
    border-color: #ddd;
}

.pricemargin-quote-container {
    max-width: 580px;
}

/*Hiding the toolbar for dialogs*/
.ip-dialog-whse .k-grid-toolbar {
    display: none;
}

.ip-dialog-selectquote .k-grid-toolbar {
    display: none;
}

.ip-dialog-grid-container .k-grid-toolbar {
    display: none;
}

.ip-dialog-parts .k-grid-toolbar {
    display: none;
}

/*Terms and Condition - Modal*/
.ip-qterms-showquote {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: -25px;
}

.ip-qterms-btnwrap {
    display: flex;
    flex-direction: row;
}

/*Icon Handling*/
.breadcrumb-item+.breadcrumb-item::before {
    font-family: "remixicon";
    font-size: 16px;
    line-height: 20px;
    float: left;
    padding-right: var(--vz-breadcrumb-item-padding-x);
    color: var(--vz-breadcrumb-divider-color);
    content: var(--vz-breadcrumb-divider, "\ea6e");
}

/*Handling*/
.mobile {
    display: none;
}

.desktop {
    display: flex;
}

.desktopblock {
    display: block;
}


.bold {
    font-weight: bold;
}

/*toastify*/
.Toastify__toast-container {
    z-index: 10500 !important;
}

.Toastify__close-button>svg {
    fill: $ipd-secondaryB !important;
}

/*mini button loader*/
.ip-miniloader-btnstatic {
    padding: 20px 25px 20px 25px;
    border-top: 1px solid $border-base;
    background-color: #f9f9f9;
}

.mini-loader {
    width: auto;
    margin-left: 34px;
    margin-right: 33px;
    display: flex;
    justify-content: center;
}

.mini-loader-footer {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
}

/*mini left nav loader*/
.ip-miniloader-lefnav {
    padding: 20px 25px 20px 25px;
    border-top: 1px solid $border-base;
    justify-content: center;
    height: calc(100% - 75px);
}

.ip-subminiloader-leftnav {
    width: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

/*Auto Save Spinner*/
.autosaving-spinner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.autosaving-spinner .text {
    font-size: 11px;
    color: #5c5c5c;
}

.autodotted-spinner {
    width: 16px;
    height: 16px;
    position: relative;
    margin-right: 8px;
}

.autodotted-spinner::before {
    content: '';
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    border: 2px dotted $ipd-primaryA;
    border-spacing: 4px;
    animation: autospin 1s linear infinite;
}

@keyframes autospin {
    to {
        transform: rotate(360deg);
    }
}

.autosave-check {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.autosave-check .text {
    font-size: 11px;
    color: #5c5c5c;
}

.autosave-check .ri-check-line {
    color: $base-green;
    font-size: 16px;
    margin-right: 4px;
}

/*Approval*/
.topbar-badge.bg-danger {
    background-color: $approval-alert-r !important;
}

.appr-msg-container {
    background-color: transparent;
    position: fixed;
    width: auto;
    height: auto;
    float: right;
    top: 0;
    right: 0;
    margin-right: -20px;
    z-index: 9;
    font-size: 12px;
    transition: all 800ms ease;
    transition-delay: 250ms;
    opacity: 0;
    visibility: hidden;
}

.appr-msg-container-show {
    top: 99px;
    transition: all 500ms ease;
    transition-delay: 200ms;
    opacity: 1;
    visibility: visible;
}

.appr-msg-container::before {
    content: '';
    position: absolute;
    box-shadow: 1px 1px 10px #ddd;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #FFF;
    transform: skew(45deg);
    z-index: -1;
}

.appr-msg-btn {
    background-color: $ipd-secondaryA;
    color: #FFF;
}

.appr-req-msg {
    color: $status-r;
    font-weight: 500;

}

.appr-msg-btn:hover {
    background-color: $ipd-primaryA;
    color: #FFF;
}

.appr-status {
    font-weight: 500;
    white-space: pre;
}

.appr-status span {
    color: initial;
    margin-right: 2px;
}

.appr-status.orange {
    color: $status-o
}

.appr-status.darkorange {
    color: $status-do
}

.appr-status.darkorange span {
    margin-top: 2px;
}

.appr-status.blue {
    color: $status-b
}

.appr-status.green {
    color: $status-g
}

.appr-status.purple {
    color: $status-p
}

.appr-status.red {
    color: $status-r
}


.appr-status i {
    font-size: 15px;
}

.appr-tooltip-msg {
    font-size: 13px;
}

.appr-tooltip-msg span {
    font-size: 11px;
    color: #cdcdcd;
}

.appr-commontxt-orange {
    color: $status-o;
    border-left: 4px solid $status-o !important;
}

.appr-commontxt-darkorange {
    color: $status-do;
    border-left: 4px solid $status-do !important;
}

.appr-commontxt-green {
    color: $status-g;
    border-left: 4px solid $status-g !important;
}

.appr-commontxt-red {
    color: $status-r;
    border-left: 4px solid $status-r !important;
}

.appr-commontxt-purple {
    color: $status-p;
    border-left: 4px solid $status-p !important;
}

.appr-commontxt-blue {
    color: $status-b;
    border-left: 4px solid $status-b !important;
}

.appr-commontxt-grey {
    color: $status-gy;
    border-left: 4px solid $status-gy !important;
}

.appr-secondarymenu-btn {
    border: 0;
    font-size: 13px;
    display: flex;
    align-items: center;
}

.appr-secondarymenu-btn span {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 10px;
    color: #FFF;
    margin-top: -12px;
    margin-left: 5px;
    background-color: $approval-alert-r;
    font-weight: 600;
}

.appr-mgr-notify-text {
    font-weight: 500;
}

.appr-mgr-notify-text span {
    color: $approval-alert-r;
}

.appr-action-radioset {
    margin-bottom: 2rem;
}

.appr-action-radioset li {
    margin-right: 1rem;
}

/*back to top*/
.back-to-top {
    position: fixed;
    bottom: 0;
    right: 0px;
    background-color: $ipd-secondaryA;
    color: #ffffff;
    border: none;
    padding: 17px 18px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 105;
    border-radius: 40px 0px 0px 0px;

}

.back-to-top i {
    left: 14px;
    top: 10px;
    position: absolute;
}

.back-to-top.show {
    opacity: 1;
}

.back-to-top:hover {
    background-color: #1659d6;
}

/*spinner*/
.ip-spinner-page .ip-loader {
    margin: auto auto;
    position: absolute;
    width: 98%;
    height: calc(100vh - 200px);
    z-index: 1000;
}

.hidden {
    display: none; /* or use visibility: hidden; */
}

.ip-spinner-page .lds-roller {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: auto auto;
    width: 100%;
    height: 75%;
    margin-left: -4%;
}

.k-dialog .ip-loader {
    margin: auto auto;
    position: absolute;
    width: 95%;
    height: 95%;
    z-index: 1000;
}

.k-dialog .lds-roller {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: auto auto;
    width: 100%;
    height: 75%;
    margin-left: -2%;
}

.ip-spin-dialog-holder {
    min-height: 580px;
}

.ip-spin-dialog-holder-sm {
    min-height: 250px;
    transition: all 0.3s ease-in-out;
}

.ip-loader {
    margin: auto auto;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.lds-roller {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: auto auto;
    width: 100%;
    height: 90%;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $ipd-primaryA;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

/*custom column menu*/
.k-menu-link-text {
    color: black !important;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media (max-width: 991.98px) {
    .mobile {
        display: block;
    }

    .desktop {
        display: none;
    }

    .desktopblock {
        display: none;
    }

    .hide-for-mobile {
        display: none;
    }

    .ip-introworkspace {
        top: 0;
    }

    .ip-spinner-page .ip-loader {
        margin: auto auto;
        position: absolute;
        width: 80%;
        height: 100%;
    }

    .ip-spinner-page .ip-loader {
        margin: auto auto;
        position: absolute;
        width: 80%;
        height: 100%;
    }

    .ip-spinner-page .lds-roller {
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        margin: auto auto;
        width: 100%;
        height: 100%;
    }

    .ip_search_container {
        max-width: none;
    }

    .ip-p-search {
        max-width: none;
    }

    .ip_labour_container {
        max-width: none;
    }

    .ip_labour_container .k-whitespace-pre {
        display: none;
    }

    .ip-qterms-showquote {
        margin-top: 10px;
    }

    .lds-roller {
        margin-left: -4%;
    }

    /*Approval*/
    .appr-msg-container::before {
        transform: skew(0deg);
    }

    .appr-msg-container {
        margin-right: 0px;
        padding: 5px;
    }

}

@media (min-width: 1500px) {

    //Handling Popups for larger screen
    .ipd-dialog-container>.k-dialog {
        min-height: 600px;
        width: 80%;
    }

    .ip-dialog-selectquote>.k-dialog {
        width: 85%;
    }
}