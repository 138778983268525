.section-box {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
    position: initial;
  }
  
  .section-box strong {
    font-weight: bold;
  }
  
  .section-box hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    border-top: 1px solid #ccc;
  }
  .absolute {
    position: absolute;
    top: 15px;
    right: 10px;
    width: 380px;
  
    border: 3px solid #7190e6;
  }
  .leftabsolute {
    position: revert;
    top: 0px;
    left: 10px;
    width: 300px;
  
    border: 3px solid #7190e6;
  }
  .bottomabsolute{
    position: initial;
    padding-top: 20px;
    left: 20px;
    width: 300px;

    border: 3px solid #7190e6;

  }
  .form-group input[readonly] {
    color: rgb(156, 152, 152); /* Change text color to grey */
}
.form-group input {
  color: rgb(6, 1, 1); /* Change text color to grey */
}
.cef-custom-input{
  width: calc(100% - 50px);
}
.cef-custom-button {
  border-radius: 4px;
  background-color: #7b8da3;
  width: 32px;
  height: 32px;
  border: 0;
  color: #FFF;
  cursor: pointer;
  margin-left: 7px;
}
.cef-custom-button:hover {
  background-color: #1a3a7b;
}
.cef-custom-button-icon {
  font-size: 18px;
  vertical-align: middle;
}