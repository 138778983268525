/*Overwrite from PDF default*/
.pdf-terms ol{
    margin-left: -1rem;
 }

 .pdf-terms.trading.preview {
    margin-top: -4rem !important;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
}

.pdf-preview-stamp {
    position: fixed;
    background-color: transparent;
    width: 100%;
    height: 100%;
    opacity: 1;
    border-radius: 6px;
}

.pdf-preview-stamp img {
    width: 100%;
    height: 100%;
    opacity: 0.8;
}

 
@media print {
    @page {
        margin: 10mm; /* Sets a 10mm margin on top, right, bottom, and left sides */
    }

    .page-break {
        page-break-after: always;
    }

    .pdf-terms {
        page-break-inside: avoid;
    }
}