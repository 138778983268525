.react-confirm-alert-body h1{
  font-size: 16px;
  background: linear-gradient(to right, $ipd-primaryA, $ipd-secondaryA);
  color: $ipd-white;
  padding: 15px 25px;
  font-weight: 400;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: -30px !important;
  margin-bottom: 25px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.react-confirm-alert-body {
  font-family: inherit !important;
  padding: 20px 30px !important;
  color: $text-default-clr !important;
  border-radius: 4px !important;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: $ipd-main-text !important;
  margin-right: 10px;
  border-radius: 4px !important;
  background: transparent !important;
  border: 1px solid #e6e6e6 !important;
  background-color: #ececec;
  min-width: 100px;
}

.react-confirm-alert-button-group > button[label="Yes"]:hover {
  background-color: $ipd-primaryA !important;
  color: $ipd-white !important;
}

.react-confirm-alert-button-group > button[label="No"]:hover {
  background-color: $ipd-primaryA !important;
  color: $ipd-white !important;
}

.react-confirm-alert-button-group {
  justify-content: end !important;
}