@font-face {
  font-family: Poppins-Regular;
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: auto;
}

@font-face {
  font-family: Poppins-Bold;
  src: url('../fonts/Poppins-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  font-display: auto;
}